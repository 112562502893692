const LightTheme = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          textTransform: "none",
        },
        sizeSmall: {
          padding: "4px 6px",
        },
      },
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          minWidth: "auto",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-selected": {
            background: "rgba(25,118,210,0.05)",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "6px 6px",
        },
      },
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    color: "rgb(21, 21, 23)",
    cardTitle: {
      // TODO: figure out how to cloneDeep the existing h1!
      fontSize: "1.1rem",
    },
    h1: {
      fontSize: "3.5rem",
    },
    h2: {
      fontSize: "2.7rem",
    },
    h3: {
      fontSize: "2.2rem",
    },
    h4: {
      fontSize: "1.8rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.25rem",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      // base of page?
      // fontSize: '14px',
    },
    subtitle1: {
      fontSize: "0.95rem",
      fontWeight: 100,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 100,
    },
  },
};

export default LightTheme;
