import React, { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import theme from "../lib/theme";

import { SharedStateProvider } from "../lib/global_state_events";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SWRConfig } from "swr";
import fetch from "../lib/fetchJson";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import TagManager from "react-gtm-module";

import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "@brainhubeu/react-carousel/lib/style.css";

import "../styles/globals.css";

// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// console.log(
//   "process.env.STRIPE_PUBLIC:",
//   process.env.NEXT_PUBLIC_STRIPE_PUBLIC
// );
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Client-side cache, shared for the whole session of the user in the browser.
// - WHY??

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;
    if (gtmId?.length) {
      // console.log("initializing tag manager");
      // ensure History Change is enabled!
      // - https://support.google.com/tagmanager/answer/7679322?hl=en
      TagManager.initialize({ gtmId });
    } else {
      // console.error("NOT initializing tag manager");
    }
  }, []);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const usedTheme = React.useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          type: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  return (
    <>
      <Script src="https://fareharbor.com/embeds/api/v1/?autolightframe=yes" />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={usedTheme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <SWRConfig
              value={{
                fetcher: fetch,
                onError: (err) => {
                  console.error(err);
                },
              }}
            >
              <SharedStateProvider>
                <Elements stripe={stripePromise}>
                  <Component {...pageProps} />
                </Elements>
              </SharedStateProvider>
            </SWRConfig>
          </QueryClientProvider>
        </ThemeProvider>
        <ToastContainer position={"bottom-right"} />
      </LocalizationProvider>
    </>
  );
}

export default MyApp;
