import { createStateContext } from "react-use";

import EventEmitter from "eventemitter3";
const eventEmitter = new EventEmitter();

export const initialState = {
  eventBus: eventEmitter,
};
const [useSharedState, SharedStateProvider] = createStateContext(initialState);

export { useSharedState, SharedStateProvider };
